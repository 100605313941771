export const errorTranslationKeyPerError: Record<string, string> = {
  required: 'form_validation.error.required',
  minlength: 'form_validation.error.min_length',
  maxlength: 'form_validation.error.max_length',
  email: 'form_validation.error.email',
  pattern: 'form_validation.error.pattern',
  atLeastOneRequired: 'form_validation.error.at_least_one_required',
  matchPassword: 'form_validation.error.match_password',
  float: 'form_validation.error.float',
  integer: 'form_validation.error.integer',
  url: 'form_validation.error.url',
  upperCase: 'form_validation.error.upper_case',
  lowerCase: 'form_validation.error.lower_case',
  number: 'form_validation.error.number',
  specialChar: 'form_validation.error.special_char',
  phone: 'form_validation.error.phone',
};
