export enum EnvironmentTypesEnum {
  beta = 'beta',
  dev = 'dev',
  prod = 'prod',
}

export enum EnvironmentPluginEnum {
  shopify = 'shopify',
}

export interface EnvironmentInterface {
  apiUrl: string;
  appVersion: string;
  dataUrl: string;
  domain: string;
  pluginMode: EnvironmentPluginEnum | null;
  postHogKey: string;
  production: boolean;
  recaptcha?: { siteKey: string };
  sentryDsn?: string;
  sentryUrl?: string;
  shopifyName?: string;
  shopifyPublic: string;
  stripePublicKey: string;
  trustedUsers: number[];
  type: EnvironmentTypesEnum;
  websocketReverb: {
    appKey: string;
    hostUrl: string;
    wsPort: number;
    wssPort: number;
  };
  whitelabel?: boolean;
}
