import { EnvironmentInterface, EnvironmentTypesEnum } from './env.model';

const defaultEnvironment: EnvironmentInterface = {
  appVersion: require('../../package.json').version,
  apiUrl: '',
  dataUrl: '',
  stripePublicKey: 'pk_test_51J56QlJvqs0qeOPOK20bOMhWnrTYxNHz7p32CBBDUehDAb1tYMKt6TkC1PcgCNhKq4cfUiGNvBYyTKH8UJsS0fwT00ITIzr85Y',
  domain: 'sembot.com',
  production: false,
  trustedUsers: [],
  type: EnvironmentTypesEnum.dev,
  pluginMode: null,
  shopifyPublic: 'd2dd01062a7c9d0dd9a80e0823ed0e28',
  recaptcha: {
    siteKey: '6LeJo44mAAAAAIHVxGTeSFueDVOp__PY1s1sRrFs', // localhost, beta, dev
  },
  websocketReverb: {
    appKey: 'njnpgnecshmb8bw7e7ps',
    hostUrl: 'ws-beta.sembot.ovh',
    wsPort: 80,
    wssPort: 443,
  },
  postHogKey: 'phc_GB7mU72TKLVlTwGW54sILKtuhdD6jNmQLYXZTsiDppa',
}; // Included with Angular CLI.

export default defaultEnvironment;
